import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { IRoleData } from "store/reducers/LoginSlice";

const Product = lazy(() => import("pages/products"));
const User = lazy(() => import("pages/user"));
const Tariff = lazy(() => import("pages/tariff"));
const Admin = lazy(() => import("pages/admin"));
const Role = lazy(() => import("pages/role"));
const News = lazy(() => import("pages/news"));
const Brand = lazy(() => import("pages/brand"));
const Color = lazy(() => import("pages/color"));
const Memory = lazy(() => import("pages/memory"));
const Chat = lazy(() => import("pages/chat"));
const Region = lazy(() => import("pages/region"));
const Settings = lazy(() => import("pages/settings"));
const Category = lazy(() => import("pages/category"));
const Statistics = lazy(() => import("pages/statistics"));
const Reason = lazy(() => import("pages/reason"));

const privateRoutes: (RouteObject & { role: keyof IRoleData })[] = [
  {
    element: <Navigate to="/product" replace />,
    path: "*",
    role: "_id",
  },
  {
    element: <Navigate to="/product" replace />,
    path: "/",
    role: "_id",
  },
  {
    element: <Statistics />,
    path: "statistics/*",
    role: "statistics",
  },
  {
    element: <Product />,
    path: "product/*",
    role: "product",
  },
  {
    element: <Tariff />,
    path: "tariff/*",
    role: "tariff",
  },
  {
    element: <User />,
    path: "users/*",
    role: "user",
  },
  {
    element: <Chat />,
    path: "chat/*",
    role: "chat",
  },
  {
    element: <Admin />,
    path: "admin/*",
    role: "admin",
  },
  {
    element: <Role />,
    path: "role/*",
    role: "role",
  },
  {
    element: <Region />,
    path: "region/*",
    role: "region",
  },
  {
    element: <News />,
    path: "news/*",
    role: "news",
  },
  {
    element: <Category />,
    path: "category/*",
    role: "category",
  },
  {
    element: <Brand />,
    path: "brand/*",
    role: "brand",
  },
  {
    element: <Color />,
    path: "color/*",
    role: "color",
  },
  {
    element: <Memory />,
    path: "memory/*",
    role: "memory",
  },
  {
    element: <Reason />,
    path: "reason/*",
    role: "reason",
  },
  {
    element: <Settings />,
    path: "settings/*",
    role: "settings",
  },
];

export default privateRoutes;
