import { Control, Controller, FieldPath } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import { TRules } from "types/form.types";
import {
  PhoneNumberCountryStyled,
  PhoneNumberlabelStyled,
} from "./PhoneInput.style";
export const phoneNumberPattern =
  /^\++[9]+[9]+[8]+[1-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+$/i;

// export const realNumberPattern =
//   /([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)/g;

export interface IPhoneInputProps<FormValues extends Record<string, any>> {
  control: Control<FormValues>;
  rules?: TRules<FormValues>;
  name: FieldPath<FormValues>;
  label?: any;
  disabled?: boolean;
  autofocus?: boolean;
}

function PhoneInput<FormValues extends Record<string, any>>({
  name,
  label = "",
  rules = {
    required: { value: true, message: "Majburiy" },
    // pattern: {
    //   value: phoneNumberPattern,
    //   message: "Telefon raqamni noto'g'ri kiritdingiz!",
    // },
  },
  control,
  disabled = false,
  autofocus = false,
}: IPhoneInputProps<FormValues>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <>
            {!!label && (
              <PhoneNumberlabelStyled>
                <label>
                  {label || name}
                  {rules.required ? (
                    <span className="text-error mt-1">*</span>
                  ) : null}
                </label>
              </PhoneNumberlabelStyled>
            )}
            <PhoneNumberCountryStyled
              country="uz"
              // onlyCountries={["uz"]}
              placeholder=""
              value={field.value}
              onBlur={field.onBlur}
              disabled={disabled}
              // disableDropdown
              inputProps={{
                ref: field.ref,
                autoFocus: autofocus,
              }}
              onChange={(val: string) => {
                field.onChange("+" + val);
              }}
            />
            {fieldState.error && (
              <>
                <h6 className="text-error mt-1">{fieldState.error.message}</h6>{" "}
              </>
            )}
          </>
        );
      }}
    />
  );
}

export default PhoneInput;
