import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";

const RefreshButton = ({ onClick }: any) => {
  return (
    <div>
      <Button onClick={onClick}>
        <RefreshIcon />
      </Button>
    </div>
  );
};

export default RefreshButton;
