import { Loading } from "components";
import { useApi } from "hooks/useApi/useApiHooks";
import { Outlet, useNavigate } from "react-router-dom";
import {
  ILoginData,
  setLoginData,
  setRoleData,
} from "store/reducers/LoginSlice";
import { useAppDispatch } from "store/storeHooks";

const AuthUser = () => {
  const dis = useAppDispatch();
  const hasToken = !!localStorage.getItem("token");
  const navigate = useNavigate();
  const { isLoading, isFetching } = useApi<ILoginData>(
    "profile",
    {},
    {
      enabled: hasToken,
      cacheTime: 0,
      staleTime: Infinity,
      onSuccess(data) {
        dis(setLoginData(data.data));
        localStorage.setItem("employeeId", data.data._id);
        if (!localStorage.getItem("i18nextLng")) {
          localStorage.setItem("i18nextLng", "uz");
        }
        dis(setRoleData(data.data.role));
        navigate("/");
      },
    }
  );

  if (isLoading || isFetching) {
    return <Loading />;
  }
  return <Outlet />;
};

export default AuthUser;
