import { ISidebarRoute } from "../sidebar.types";

export const sidebarRoutes: ISidebarRoute[] = [
  {
    path: "/statistics",
    translate: "statistics",
    role: "statistics",
  },
  {
    path: "/product",
    translate: "product",
    role: "product",
  },
  {
    path: "/tariff",
    translate: "tariff",
    role: "tariff",
  },
  {
    path: "/users",
    translate: "users",
    role: "user",
  },
  {
    path: "/chat",
    translate: "chat",
    role: "chat",
  },
  {
    path: "/news",
    translate: "news",
    role: "news",
  },
  {
    path: "/admin",
    translate: "admin",
    role: "admin",
  },
  {
    translate: "settings",
    role: "_id",
    items: [
      {
        path: "/role",
        translate: "role",
        role: "role",
      },
      {
        path: "/region",
        translate: "region",
        role: "region",
      },
      {
        path: "/category",
        translate: "category",
        role: "category",
      },
      {
        path: "/brand",
        translate: "brand",
        role: "brand",
      },
      {
        path: "/color",
        translate: "color",
        role: "color",
      },
      {
        path: "/memory",
        translate: "memory",
        role: "memory",
      },
      {
        path: "/reason",
        translate: "reason",
        role: "reason",
      },
      {
        path: "/settings",
        translate: "general_settings",
        role: "settings",
      },
    ],
  },
];
