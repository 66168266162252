import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export type ILoginData = typeof initialState.loginData;
export type IRoleData = typeof initialState.role;

const initialState = {
  isAuth: false,
  isConnected: false,
  isFree: false,
  role: {
    _id: "",
    admin: false,
    admin_create: false,
    admin_update: false,
    admin_delete: false,
    product: false,
    product_create: false,
    product_update: false,
    product_delete: false,
    tariff: false,
    tariff_create: false,
    tariff_update: false,
    tariff_delete: false,
    brand: false,
    brand_create: false,
    brand_update: false,
    brand_delete: false,
    color: false,
    color_create: false,
    color_update: false,
    color_delete: false,
    role: false,
    role_create: false,
    role_update: false,
    role_delete: false,
    news: false,
    news_create: false,
    news_update: false,
    news_delete: false,
    chat: false,
    chat_create: false,
    chat_update: false,
    chat_delete: false,
    memory: false,
    memory_create: false,
    memory_update: false,
    memory_delete: false,
    region: false,
    region_create: false,
    region_update: false,
    region_delete: false,
    category: false,
    category_create: false,
    category_update: false,
    category_delete: false,
    reason: false,
    reason_create: false,
    reason_update: false,
    reason_delete: false,
    user: false,
    settings: false,
    statistics: false,
  },
  loginData: {
    _id: "",
    full_name: "",
    phone_number: "",
    role_id: "",
    role: {},
    created_at: "",
    token: "",
  },
};

const LoginSlice = createSlice({
  name: "login-data",
  initialState,
  reducers: {
    setLoginData(state, payload: PayloadAction<ILoginData>) {
      state.loginData = payload.payload;
      state.isAuth = true;
    },
    setRoleData(state, payload) {
      state.role = { ...state.role, ...payload.payload };
    },
    setSocketConnect(state, payload: PayloadAction<boolean>) {
      state.isConnected = payload.payload;
    },
    setIsFree(state, payload: PayloadAction<boolean>) {
      state.isFree = payload.payload;
    },
  },
});

export const { setLoginData, setRoleData, setSocketConnect, setIsFree } =
  LoginSlice.actions;

export default LoginSlice.reducer;
