import { useApiMutation } from "hooks/useApi/useApiHooks";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/storeHooks";
import { SIDEBAR_CLOSE, SIDEBAR_OPEN } from "styles/global.style";
import { NavbarContainer } from "./Navbar.style";

import { Popover } from "@mui/material";
import { ProfileIcon } from "assets/svgs";
import WarningModal from "components/common/WarningModal/WarningModal";
import { get } from "lodash";
import { useRoleManager } from "services/useRoleManager";
import { socket } from "socket";
import { CommonContext } from "context";

const Navbar = ({ hasNavbar }: { hasNavbar: boolean }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [newNotification, setNewNotification] = useState<any>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const hasAccess = useRoleManager();
  const [limit, setLimit] = useState(10);
  const [exit, setExit] = useState(false);
  // const [open, setOpen] = useState(false);
  const popoverId = open ? "simple-popover" : undefined;

  const {
    actions: { setRender },
  } = useContext(CommonContext);

  const { mutate } = useApiMutation<{
    // _id: string;
    currentBranchId: string;
  }>("employee/branch", "post", {
    onSuccess(data, variables, context) {
      if (pathname?.split("/")?.some((path) => path?.length === 24)) {
        navigate("order/table");
      }
      navigate(0);
    },
  });
  const { value } = useAppSelector((state) => state.sideBarData);
  const { loginData } = useAppSelector((state) => state.LoginState);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const openUser = Boolean(anchorElUser);
  const handleClickUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const logoutUser = () => {
    localStorage.clear();
    navigate("/login", {
      replace: false,
    });
  };

  useEffect(() => {
    socket.connect();

    socket.on("new_message", () => {
      setRender((prev: boolean) => !prev);
    });

    return () => {
      socket.off("new_message");
    };
  }, []);

  return (
    <NavbarContainer>
      <div
        style={{ left: value ? SIDEBAR_OPEN : SIDEBAR_CLOSE }}
        className="navbar-content"
      >
        <div className="profile">
          <h4 className="me-3">{get(loginData, "full_name", "")}</h4>
          <span
            className="icon"
            aria-describedby={popoverId}
            onClick={(e: any) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          >
            <ProfileIcon />
          </span>
          <Popover
            id={popoverId}
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            className="popover-container"
          >
            <div className="popover">
              {/* <h4>{get(loginData, 'firstName', '')} {get(loginData, 'lastName', '')}</h4> */}
              <span>{get(loginData, "phone_number", "")}</span>
              {/* {stores?.length > 1 && (
                <button className="change-market" onClick={() => navigate('/store')}>Marketni o'zgartirish</button>
              )} */}
              <button className="logout" onClick={() => setExit(true)}>
                Chiqish
              </button>
            </div>
          </Popover>
          <WarningModal
            open={exit}
            setOpen={setExit}
            title="Rostdan ham chiqmoqchimisiz?"
            confirmFn={logoutUser}
          />
        </div>
        {/* <NavbarSelectStyled>
          <Select
            // optionsUrl="branch/all"
            iconValue={<NavSelectIcon />}
            options={role?.map((rol) => rol.branch)}
            // defaultFirst={true}
            customValue={currentBranch?._id || role?.[0].branch?._id}
            onChange={(val) =>
              mutate({
                currentBranchId: val as string,
                // _id: localStorage.getItem("employeeId") || "",
              })
            }
          />
        </NavbarSelectStyled> */}
        {/* <div className="navbar-right-container">
          {hasAccess("dispatcher") && (
            <Box>
              <Switch
                checked={isFree}
                onChange={handleChangeIsFree}
                disabled={!isConnected}
              />
            </Box>
          )}
          {hasAccess("paymentConfirm") && (
            <div className="notification">
              <div
                className="icon"
                onClick={() => {
                  if (!open) {
                    getUnconfirmedContracts("");
                  }
                  setOpen(!open);
                }}
              >
                <NotificationIcon />
                {!!unconfirmedContracts?.length && (
                  <span className="red"></span>
                )}
              </div>
              {open && (
                <div className="list">
                  <h4>Bildirishnomalar</h4>
                  {unconfirmedContracts?.map((item: any) => (
                    <div
                      className="item"
                      key={item?._id}
                      onClick={() => {
                        navigate(`/contract/view/${item?._id}`);
                      }}
                    >
                      <span className="key">
                        <NotificationKeyIcon />
                      </span>
                      <div className="text">
                        <span className="contract-number">
                          Shartnoma &nbsp; #{item.contractNumber}
                        </span>
                        <span className="status">Tasdiq kutilmoqda...</span>
                      </div>
                      <div className="time">
                        {dayjs(item.createdAt).format("DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                  ))}
                  <div className="load-more">
                    <span
                      className="load-more-icon"
                      onClick={() => setLimit((prev) => prev + 10)}
                    >
                      <LoadMoreIcon />
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
          <ChangeLang />
          <NavbarUserProfStyled>
            <Button
              id="basic-button"
              aria-controls={openUser ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openUser ? "true" : undefined}
              onClick={handleClickUser}
              className={anchorElUser ? "user_btn active" : "user_btn"}
            >
              <span>
                {firstName} {lastName}
              </span>
              <div className="icon_box">
                <Arrow />
              </div>
            </Button>

            <MenuItemStyled
              className="menu_user"
              id="basic-menu"
              anchorEl={anchorElUser}
              open={openUser}
              onClose={() => setAnchorElUser(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={logoutUser}>
                <LogoutIcon className="icon" />
                Logout
              </MenuItem>
            </MenuItemStyled>
          </NavbarUserProfStyled>
        </div> */}
      </div>
    </NavbarContainer>
  );
};

export default Navbar;
