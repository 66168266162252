import axios from "axios";
import { useState } from "react";

const usePostImage = (onSuccess?: (url: string) => void) => {
  const [image, setImage] = useState("");
  const [isUploading, setIsUploading] = useState<boolean>();
  const uploadImage = (file: File) => {
    setIsUploading(true);
    if (file) {
      axios({
        url: `${process.env.REACT_APP_BASE_URL}/upload`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          storeId: localStorage.getItem("storeId"),
        },
        data: {
          file: file,
          type: "img",
        },
      }).then((res) => {
        onSuccess?.(res.data.data);
        setImage(res.data.data);
        setIsUploading(false);
      });
    }
  };
  return { image, isUploading, uploadImage, setImage };
};

export default usePostImage;
