import { useState } from "react";

import { useRequest } from "hooks/useApi/useRequest";

export type RUseCommon = ReturnType<typeof useCommon>;

export const useCommon = () => {
  const [render, setRender] = useState<boolean>(false);
  const [chatId, setChatId] = useState<string>("");
  const [chatRead, setChatRead] = useState<boolean>(false);

  const [makeReadClient, makeReadData, makeReadStatus, makeReadError] =
    useRequest();

  const [getChatsClient, getChatsData, getChatsStatus, getChatsError] =
    useRequest();

  const makeRead = async (_id: string) => {
    await makeReadClient.post("chat/mark-as-read", {
      _id,
    });
  };

  const getChats = async (search?: string) => {
    await getChatsClient.post(`/chat/users`, {});
  };

  return {
    state: {
      makeReadState: {
        makeReadData,
        makeReadStatus,
        makeReadError,
      },
      getChatsState: {
        getChatsData,
        getChatsStatus,
        getChatsError,
      },

      chatId,
      chatRead,
      render,
    },
    actions: {
      setRender,
      makeRead,
      setChatId,
      getChats,
      setChatRead,
    },
  };
};
